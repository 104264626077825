// import React from "react";
// import styled from "styled-components";
// import productImage from "../assets/images/emergency-response.png"; // Adjust the path to your image

// // Styled components
// const Container = styled.div`
//   display: flex;
//   flex-direction: row;
//   // align-items: center;
//   // justify-content: center;
//   padding: 2rem;
//   background-color: #343275; /* Background color for the page */
//   color: white;
//   min-height: calc(
//     100vh - 120px - 2rem
//   ); /* Adjust height to account for the NavBar height */
//   gap: 2rem; /* Space between text and image */
//   flex-wrap: wrap; /* Allows wrapping on smaller screens */
// `;

// const TextSection = styled.div`
//   flex: 1;
//   /* Limit the width of the text section */
//   padding-right: 1rem; /* Add space between text and image */
// `;

// const Title = styled.h1`
//   font-family: "Open Sans", sans-serif;
//   font-size: 2.5rem;
//   margin-bottom: 1rem;
// `;

// const Description = styled.p`
//   font-size: 1.2rem;
//   text-align: left;
// `;

// const ImageSection = styled.div`
//   flex: 1;
//   background-color: #ffffff; /* White background for the image section */
//   border-radius: 8px;
//   height: 400px;
//   display: flex; /* Use flexbox to center the image */
//   justify-content: center; /* Center horizontally */
//   // align-items: center; /* Center vertically */
//   border: 1px solid black;
//   padding: 1rem; /* Padding around the image */
// `;

// const Image = styled.img`
//   max-width: 30%; /* Ensure image doesn't overflow */
//   height: 400px;
//   border-radius: 8px;
// `;

// const Product = () => (
//   <Container>
//     <TextSection>
//       <Title>Svasth QRify</Title>
//       <Description>
//         The Svasth Emergency Response System <b>QRify</b> is designed to provide
//         rapid and effective emergency assistance. Utilizing advanced AI
//         algorithms and real-time data analysis, this system ensures timely
//         response and efficient handling of emergencies. Whether it's medical,
//         fire, or any other type of urgent situation, Svasth is equipped to
//         handle it swiftly and accurately, minimizing risks and improving
//         outcomes.
//       </Description>
//     </TextSection>
//     <ImageSection>
//       <Image src={productImage} alt="Svasth Emergency Response System" />
//     </ImageSection>
//   </Container>
// );

// export default Product;
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import Layout from "../components/Layout";
import background from "../assets/images/background.png";
import BackgroundImage from "../components/backgroundImage";
import ImageComponent from "../components/Images";
import page2 from "../assets/images/page2.png";
import icon from "../assets/images/icon.png";
import Card5 from "../components/Card5";
import card41 from "../assets/images/card41.png";
import card42 from "../assets/images/card42.png";
import card43 from "../assets/images/card43.png";
import building from "../assets/images/building.png";
import accident from "../assets/images/accident.png";
import recover from "../assets/images/recover.png";
import Svasthlogo from "../assets/images/Svasthlogo.png";
import Button from "../components/Button";
import heartattack from "../assets/images/heartattack.png";
import hospital from "../assets/images/hospital.png";
import Card4 from "../components/Card4";
import yoga from "../assets/images/yoga.png";
import Footer from "../components/Footer";
import mobile from "../assets/images/mobile.png";
import BlackCard from "../components/Blackcard";
import TextColor from "../components/TextColor";
import Header from "../components/Navbarmobile";
import NavBar from "../components/NavBar";
const Product = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/about");
  };
  const contactClick = () => {
    navigate("/contact");
  };
  return (
    <Stack
      display={"flex"}
      sx={{ position: "relative", gap: 8 }}
      justifyContent={"center"}
    >
      <Layout>
        <NavBar logo={Svasthlogo} logoHeight="80px" logoWidth="205px" />
        <Header
          logoImage={Svasthlogo}
          style={{
            height: "34px",
            width: "86.71px",
          }}
        />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            "@media (max-width: 600px)": {
              alignItems: "center",
              flexWrap: "wrap",
            },
          }}
        >
          <Stack width={{ xs: "100%", sm: "57%" }} gap={1}>
            <Stack
              sx={{
                "@media (max-width: 600px)": {
                  textAlign: "center",
                },
              }}
            >
              <TextColor
                text="What If Healthcare Could "
                highlightWords={[]}
                highlightColor="#000000"
                defaultColor="#000000"
                styleType="h31"
              />
              <TextColor
                text=" Be Smarter?"
                highlightWords={["Smarter"]}
                highlightColor="#008000"
                defaultColor="#000000"
                styleType="h31"
              />
            </Stack>

            <Stack
              sx={{
                "@media (max-width: 600px)": {
                  textAlign: "center",
                },
              }}
            >
              <TextColor
                text=" Our innovative solutions deliver rapid response, precise "
                highlightWords={[]}
                highlightColor="#000000"
                defaultColor="#000000"
                styleType="body11"
              />
              <TextColor
                text=" diagnoses, and significant savings for a new era of "
                highlightWords={[]}
                highlightColor="#00000"
                defaultColor="#000000"
                styleType="body11"
              />
              <TextColor
                text=" healthcare excellence. "
                highlightWords={["healthcare", "excellence"]}
                highlightColor="#FF0000"
                defaultColor="#000000"
                styleType="body11"
              />
            </Stack>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={8}
              sx={{
                "@media (max-width: 600px)": {
                  gap: 2,
                },
              }}
            >
              <Stack
                flexWrap={"wrap-reverse"}
                alignItems={"flex-end"}
                sx={{
                  "@media (max-width: 600px)": {
                    alignItems: "center",
                  },
                }}
              >
                <Button
                  text="Learn More"
                  textColor="#ffffff"
                  onClick={handleClick}
                />
              </Stack>
              <Box
                component="img"
                src={icon}
                alt="Description"
                sx={{
                  width: "35.78vw",
                  height: "2.29vw",

                  "@media (max-width: 600px)": {
                    width: "327px",
                    height: "22px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                  },
                }}
              />
            </Box>
          </Stack>
          <Stack
            width="50%"
            minWidth="340px"
            background="#fff222"
            justifyContent="center"
            alignItems={"center"}
          >
            <ImageComponent
              style={{ width: "100%", height: "100%", position: "relative" }}
              Image={page2}
            />
          </Stack>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{
            "@media (max-width: 600px)": {
              alignItems: "center",
              flexWrap: "wrap-reverse",
            },
          }}
        >
          <Stack
            width="50%"
            minWidth="340px"
            background="#fff222"
            justifyContent="center"
            alignItems={"center"}
          >
            <ImageComponent
              style={{ width: "100%", height: "100%", position: "relative" }}
              Image={mobile}
            />
          </Stack>
          <Stack
            width={{ xs: "100%", sm: "50%" }}
            minWidth="340px"
            background="#fff222"
            justifyContent="center"
            sx={{
              "@media (max-width: 600px)": {
                width: "320px",
                textAlign: "center",
              },
            }}
          >
            <TextColor
              text=" About QRify"
              highlightWords={["QRify"]}
              highlightColor="#303A99"
              defaultColor="#000000"
              styleType="h41"
            />
            <TextColor
              text="QRify is a one-of-a-kind emergency response tool designed to save lives. By using unique QR codes, it links Lifesavrs to the patient’s emergency contacts, ambulances and hospitals. In a crisis, QRify ensures rapid coordination between emergency services and the victim. Its real-time alerts and precise location-based dispatch streamline the response process, improving outcomes and reducing delays. QRify is the ultimate solution for efficient and life-saving emergency management."
              highlightWords={[""]}
              highlightColor="#000000"
              defaultColor="#000000"
              styleType="body32"
            />
          </Stack>
        </Box>
        {/* <BlackCard
          styles={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ffffff",
            color: "#000000",
            height: "582px",
            width: "50%"
          }}
         
          background={"#ffffff"}
          textcolor={"#000000"}
          imgWidth="780px"
          imageLeft={true}
          image={mobile}
          title1="About QRify"
          body=" QRify is a one-of-a-kind emergency response tool designed to save lives. By using unique QR codes, it links Lifesavrs to the patient’s emergency contacts, ambulances and hospitals. In a crisis, QRify ensures rapid coordination between emergency services and the victim. Its real-time alerts and precise location-based dispatch streamline the response process, improving outcomes and reducing delays. QRify is the ultimate solution for efficient and life-saving emergency management."
        /> */}

        <Box display="flex" flexDirection="column" paddingBottom={10} gap={2}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextColor
              text="Why QRify Matters"
              highlightWords={["QRify", "Matters"]}
              highlightColor="#303A99"
              defaultColor="#000000"
              styleType="h31"
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              flexWrap: "wrap",
            }}
          >
            <Card4
              title="Instant Alert System"
              description=" With real-time notifications, our system immediately sends accident data to emergency centers and contacts, reducing response time."
              imageSrc={card41}
            />
            <Card4
              title="Healthcare Connection"
              description="Links with nearby hospitals for prompt treatment and facilitates seamless communication between emergency services."
              imageSrc={card42}
            />
            <Card4
              title="Hassle-Free Dispatch"
              description=" Streamlined dispatch eliminates delays and saves time by avoiding unnecessary negotiations with the ambulance team during emergencies."
              imageSrc={card43}
            />
          </Box>
        </Box>
      </Layout>
      <Layout backgroundColor={"linear-gradient(to right, #1e1e1e, #0a192f)"}>
        <Box>
          <BlackCard
            image={accident}
            imageLeft={false}
            substyle={{ fontSize: "32px" }}
            title="Quick Response to Accidents"
            subtitle="Lifesaver’s Swift Aid"
            description="Imagine Priya, a 35-year-old driver, involved in a serious car accident. Unconscious and vulnerable, she still receives swift emergency assistance thanks to the lifesaver at the scene."
            buttonText="Contact Us"
            buttonBackgroundColor={"#ffffff"}
            onClick={contactClick}
            buttontextColor={"#303A99"}
          />
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              background: "linear-gradient(to right, #1e1e1e, #0a192f)",
              gap: 6,
              "@media (max-width: 600px)": {
                gap: 1,
              },
            }}
          >
            <Card5
              heading="STEP 01"
              title="Instant Scan"
              description="A lifesaver scans the QRify code on Priya’s car, instantly triggering the emergency response process. Real-time alerts ensure immediate and effective assistance."
            />
            <Card5
              heading="STEP 02"
              title="Immediate Alerts"
              description="Svasth sends urgent notifications to Priya’s emergency contacts & alerts local emergency services, dispatching an ambulance right away."
            />
            <Card5
              heading="STEP 03"
              title="Exact Location Sharing"
              description="Priya’s precise location of the accident site is shared with emergency responders, ensuring they arrive quickly and efficiently."
            />
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              sx={{
                alignItems: "center",
                justifyContent: "center",
                background: "linear-gradient(to right, #1e1e1e, #0a192f)",
                gap: 6,
                "@media (max-width: 600px)": {
                  gap: 1,
                },
              }}
            >
              <Card5
                height="300px"
                width="580px"
                heading="STEP 04"
                title="Streamlined Ambulance Service"
                description="The solution ensures a smooth connection with emergency responders, avoiding negotiations and cancellations, and facilitating prompt, reliable service."
              />
              <Card5
                height="300px"
                width="580px"
                heading="STEP 05"
                title="Seamless Hospital Coordination"
                description="Svasth coordinates with the ambulance & hospital in advance, ensuring swift medical attention and handling any necessary payments* seamlessly."
              />
              <Box
                component="img"
                src={hospital}
                alt="Description"
                sx={{
                  width: "15.67%",
                  height: "15.67%",
                  "@media (max-width: 600px)": {
                    display: "none",
                  },
                }}
              />
            </Box>
          </Box>
          <BlackCard
            imageLeft={true}
            background={"linear-gradient(to right, #1e1e1e, #0a192f)"}
            textcolor={"#ffffff"}
            image={recover}
            buttonBackgroundColor={"#ffffff"}
            buttontextColor={"#303A99"}
            subtitle="Every Life Secured"
            description="With Svasth’s QRify, Priya gets fast, effective care right after her accident. Quick alerts and smooth coordination bring comfort to her loved ones. Priya is happy and safe."
            buttonText="Contact us"
            onClick={contactClick}
          />
        </Box>
      </Layout>
      <Layout>
        <Box>
          <BlackCard
            image={heartattack}
            imageLeft={false}
            substyle={{ fontSize: "32px" }}
            textcolor={"#000000"}
            background={"Transparent"}
            buttonBackgroundColor={"#303A99"}
            buttontextColor={"#ffffff"}
            title="Emergency Response for a Sudden Heart Attack"
            subtitle="Urgent Care Delivered"
            description=" Imagine Mohan, a 60-year-old office worker, suddenly collapsing at work due to his history of heart disease. His colleagues take quick action to ensure Mohan receives the urgent care he needs."
            buttonText="Contact us"
            onClick={contactClick}
          />
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              background: "Transparent",
              gap: 6,
              "@media (max-width: 600px)": {
                gap: 1,
              },
            }}
          >
            <Card5
              background="transparent"
              textcolor="#000000"
              heading="STEP 01"
              title="Instant Scan"
              description="A colleague quickly scans Mohan’s QRify code on his ID badge, instantly activating the emergency response protocol."
            />
            <Card5
              background="transparent"
              textcolor="#000000"
              heading="STEP 02"
              title="Real-Time Alerts"
              description="Svasth sends immediate alerts to nearby emergency contacts and services, dispatching an ambulance without delay."
            />
            <Card5
              background="transparent"
              textcolor="#000000"
              heading="STEP 03"
              title="Precise Location"
              description="Mohan’s exact office location is shared with emergency responders, guiding them directly to him."
            />
            <Card5
              background="transparent"
              textcolor="#000000"
              height="300px"
              width="580px"
              heading="STEP 04"
              title="Effortless Ambulance Coordination"
              description="The system provides ambulance services thereby avoiding price negotiations, cancellations, and unnecessary delays, ensuring reliable response."
            />
            <Card5
              background="transparent"
              textcolor="#000000"
              height="300px"
              width="580px"
              heading="STEP 05"
              title="Seamless Hospital Coordination"
              description="The ambulance and hospital are prepped and connected via Svasth, handling emergency payments* and streamlining Mohan’s transfer."
            />
            <Box
              component="img"
              src={building}
              alt="Description"
              sx={{
                width: "15.67%",
                height: "15.67%",
                "@media (max-width: 600px)": {
                  display: "none",
                },
              }}
            />
          </Box>
          <BlackCard
            imageLeft={true}
            background={"transparent"}
            textcolor={"#000000"}
            image={yoga}
            buttonBackgroundColor={"#303A99"}
            buttontextColor={"#ffffff"}
            subtitle="Every Life Secured"
            description="With Svasth’s QRify, Priya gets fast, effective care right after her accident. Quick alerts and smooth coordination bring comfort to her loved ones. Priya is happy and safe."
            buttonText="Contact us"
            onClick={contactClick}
          />
        </Box>
      </Layout>
      <Footer />
    </Stack>
  );
};

export default Product;
