import React from "react";
import { Box, Typography, CardMedia } from "@mui/material";
import Button from "../components/Button";
import Layout from "../components/Layout";
import { FontStyle } from "../assets/styles";
import { Navigate, useNavigate } from "react-router-dom";

const BlackCard = ({
  image,
  title,
  title1,
  subtitle,
  substyle,
  description,
  buttonText,
  imageLeft,
  body,
  background,
  textcolor,
  buttonBackgroundColor,
  buttontextColor,
  onClick,
}) => {
  console.log("color", buttontextColor);
  return (
    <Box
      sx={{
        width: "100%",
        justifyContent: "flex-start",
        background: background
          ? background
          : "linear-gradient(to right, #1e1e1e, #0a192f)",
        color: textcolor ? textcolor : "#ffffff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
        sx={{
          pt: { xs: 2, md: 8 },
          pb: { xs: 2, md: 8 }, // Smaller padding for mobile
          gap: { xs: 2, md: 8 },
          // Smaller gap for mobile
        }}
      >
        {title && (
          <Typography
            sx={{
              ...FontStyle.h41,
              textAlign: "left", // Default alignment for larger screens
              "@media (max-width: 600px)": {
                textAlign: "center", // Center the text for 360px screens
              },
            }}
          >
            {title}
          </Typography>
        )}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            gap: { xs: 1, md: 4 },
            flexWrap: "wrap-reverse",
            width: "100%",
            "@media (max-width: 600px)": {
              flexDirection: imageLeft ? "column" : "column-reverse",
            },
          }}
        >
          {imageLeft && (
            <CardMedia
              component="img"
              image={image}
              sx={{
                borderRadius: "16px",
                width: { xs: "100%", md: "44.2vw" },
                height: { xs: "auto", md: "28.9vw" },
              }}
            />
          )}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            sx={{
              width: { xs: "100%", md: "37.6vw" },
              gap: { xs: 1, md: 4 },
            }}
          >
            <Typography sx={FontStyle.h31}>{title1}</Typography>
            <Typography sx={FontStyle.sub13}>{subtitle}</Typography>
            <Typography sx={FontStyle.body11}>{description}</Typography>
            <Typography sx={FontStyle.body41}>{body}</Typography>

            {buttonText && (
              <Button
                text={buttonText}
                onClick={onClick}
                backgroundColor={
                  buttonBackgroundColor ? buttonBackgroundColor : "#ffffff"
                }
                textColor={buttontextColor ? buttontextColor : "#000000"}
                sx={{
                  "@media (max-width: 600px)": {
                    justifyContent: "flex-Start",
                  },
                }}
              />
            )}
          </Box>
          <Box>
            {!imageLeft && (
              <CardMedia
                component="img"
                image={image}
                sx={{
                  borderRadius: "16px",
                  width: { xs: "100%", md: "44vw" },
                  height: { xs: "auto", md: "28vw" },
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BlackCard;

// import React, { useEffect } from "react";
// import { Box, Typography, CardMedia } from "@mui/material";
// import Button from "../components/Button";
// import Layout from "../components/Layout";
// import { FontStyle } from '../assets/styles';

// const BlackCard = ({
//   image,
//   title,
//   title1,
//   subtitle,
//   substyle,
//   description,
//   buttonText,
//   imageLeft,
//   body,
//   background,
//   textcolor,
//   buttonBackgroundColor,
//   buttontextColor,
// }) => {
//   const height=window.innerHeight;
//   const width=window.innerWidth;
//   console.log(height,width)

//   return (
//     <Box
//       sx={{
//         width: "100%",
//         justifyContent: "flex-start",
//         background: background
//           ? background
//           : "linear-gradient(to right, #1e1e1e, #0a192f)",
//         color: textcolor ? textcolor : "#ffffff",
//         display: "flex",
//         flexDirection: "column",

//       }}
//     >

//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         flexDirection={"column"}
//         sx={{

//           pt:8,
//           pb:8,
//           gap:8,

//           }}
//       >
//         {title && (
//           <Typography sx={{...FontStyle.h43}}>{title}</Typography>
//         )}

//         <Box
//           display="flex"
//           flexDirection="row"
//           justifyContent="center"
//           alignItems="center"
//           sx={{

//             gap: 4,

//             flexWrap:"wrap",
//             // minWidth:"800px"
//             maxWidth:"100%",

//           }}>
//           {imageLeft && (

//               <CardMedia
//                 component="img"
//                 image={image}
//                 sx={{
//                   borderRadius: "16px",
//                   minWidth:320,
//                   width: "44.2vw",
//                   height: "28.9vw",
//                 }}
//               />

//           )}
//           <Box
//             display="flex"
//             flexDirection="column"
//             alignItems="center"
//             justifyContent={"flex-start"}
//             alignContent="center"
//             sx={{ margin: imageLeft ? 0 : "0 32px 0 0",}}
//           >
//             <Box
//               display="flex"
//               flexDirection="column"
//               alignItems={"flex-start"}
//               height={"20.5vw"}
//               minWidth={320}
//               width={"37.6vw"}
//               sx={{ gap: 4 }}
//             >
//                <Typography sx={FontStyle.h31}>{title1}</Typography>
//               <Typography sx={FontStyle.sub13}>{subtitle}</Typography>
//               <Typography sx={FontStyle.body11}>{description}</Typography>
//               <Typography sx={FontStyle.body41}>{body}</Typography>
//               {buttonText && (
//                 <Button
//                   text={buttonText}
//                   backgroundColor={buttonBackgroundColor ? buttonBackgroundColor : "#ffffff"}
//                   color={buttontextColor ? buttontextColor : "#000000"}
//                 />
//               )}
//             </Box>
//           </Box>
//           {!imageLeft && (
//                 <CardMedia
//                 component="img"
//                 image={image}
//                 sx={{
//                   borderRadius: "16px",
//                   width: "44vw",
//                   height: "28vw",

//                 }}
//               />
//             )}
//         </Box>
//       </Box>
//       </Box>

//   );
// };

// export default BlackCard;
