import React from "react";
import { TextField } from "@mui/material";

const CustomTextInput = ({
  label,
  isValid,

  placeholder,
  onChangeText,
  error,
  value,
  maxlength,
}) => (
  <div style={{ width: "100%" }}>
    {/* <label>{label}</label> */}
    <TextField
      placeholder={placeholder}
      helperText={!error ? " " : "Invalid OTP"}
      error={error}
      label={label}
      value={value}
      maxLength={maxlength}
      onChange={(e) => onChangeText(e.target.value)}
      sx={{ width: "100%", fontSize: 14 }}
    />

    {/* <input
      type="text"
      placeholder={placeholder}
      onChange={(e) => onChangeText(e.target.value)}
      maxLength={maxlength}
      style={{
        display: "block",
        width: "100%",
        padding: "10px",
        borderRadius: "5px",
        border: "1px solid #ccc",
        marginBottom: "8px",
      }}
    /> */}
    {error && <span style={{ color: "red" }}>{error}</span>}
  </div>
);

export default CustomTextInput;
