import { Box, TextField, Typography, Grid, colors } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import TextColor from "../components/TextColor";
import Our from "../components/Our";
import TextComponent from "../components/Text";
import Button from "../components/Button";
import ContactForm from "../components/input";
import A from "../assets/images/mail.png";
import B from "../assets/images/phone1.png";
import C from "../assets/images/location.png";
import Footer from "../components/Footer";
import InText from "../assets/images/intext.png";
import FText from "../assets/images/ftext.png";
import { FontStyle } from "../assets/styles";
import Header from "../components/Navbarmobile";
import Svasthlogo from "../assets/images/Svasthlogo.png";
import NavBar from "../components/NavBar";
import Layout from "../components/Layout";

// Styled components
const Container = styled.div`
  // display: flex;
  // // text-aline: cneter
  // justify-Content: 'center';
  // flex-direction: column;
  // align-items: center;
  // aline-Content: center;
  // padding: 3px;
  // // background-color: #343275; /* Set background color to #343275 */
  // // min-height: calc(
  //   // 130vh - 120px - 2rem
  // ); /* Adjust height to account for the NavBar height */
  // color: black; /* Set text color to white for better readability */
  // broder
  //
`;

const styles = {
  iconImage: {
    width: "30px",
    height: "30px",
    marginRight: "8px",
    verticalAlign: "middle",
    colors: "#ffffff",
  },
};

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form data submitted:", formData);
  };

  return (
    <Box>
      <Layout>
        <NavBar logo={Svasthlogo} logoHeight="80px" logoWidth="205px" />
        <Header
          logoImage={Svasthlogo}
          style={{
            height: "34px",
            width: "86.71px",
          }}
        />
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            p: 1,
            gap: 8,
            "@media(max-width:360px)": { gap: 3 },
          }}
        >
          <Box
            display={"flex"}
            sx={{ justifyContent: "center", alignContent: "center" }}
          >
            <TextColor
              text="Contact us"
              highlightWords={["Contact", "us"]}
              highlightColor="#3f51b5"
              defaultColor="#C9C9C9"
              styleType="h5"
            />
          </Box>
          <Box
            display={"flex"}
            minWidth={"360px"}
            flexWrap={"wrap"}
            sx={{
              border: 1,
              borderRadius: "15px",
              mb: "20px",
              "@media(max-width:360px)": {
                border: "none",
                gap: 3,
                minWidth: "318px",
              },
            }}
          >
            {/* <Box display={"flex"} sx={{ height: '796px', width: '31.5vw', borderRadius: "15px", background: "black", justifyContent: "center", }}> */}
            <Box
              display={"flex"}
              sx={{
                flexDirection: "column", // Column on mobile, row on larger screens
                background: "#000000",
                p: { xs: 3, md: 4 }, // Smaller padding on mobile
                // minWidth: { xs: '100vw', md: 'auto' }, // Full width on mobile
                // '@madia (max_width: 360)': {
                //   borderRadius: "15px"
                // },
                gap: 4,
                borderRadius: "15px",
                "@media(max-width:360px)": { gap: 4 },
              }}
            >
              {/* Text Section */}
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  height: { xs: "auto" },
                  width: { xs: "100%", md: "25.6vw" }, // Full width on mobile
                  gap: { xs: 1, md: 3 },
                  "@media(max-width:360px": {
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  },
                }}
              >
                <TextColor
                  text="Let’s Create a Healthier Tomorrow Together "
                  highlightWords={["Tomorrow", "Together"]}
                  highlightColor="#3f51b5"
                  defaultColor="#C9C9C9"
                  styleType="sub11"
                />
                <TextColor
                  text={`Your health and well-being are 
            our top priorities. Whether you have 
            questions, need support, or want to 
            learn more about how our innovative 
            solutions can make a difference, 
            we're here for you.`}
                  highlightWords={["Tomorrow", "Together"]}
                  highlightColor="#3f51b5"
                  defaultColor="#C9C9C9"
                  styleType="body54"
                />
              </Box>

              {/* Contact Info Section */}
              <Box sx={{ color: "white", p: { xs: 0, md: 2 } }}>
                <Box sx={styles.footerSection}>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: { xs: "12px", md: "16px" } }}
                  >
                    <img src={A} alt="Email Icon" style={styles.iconImage} />{" "}
                    info@svasth.ai
                  </Typography>
                  <br />
                  <Typography
                    variant="body2"
                    sx={{ fontSize: { xs: "12px", md: "16px" } }}
                  >
                    <img src={B} alt="Phone Icon" style={styles.iconImage} />{" "}
                    +91 999 999 9999
                  </Typography>
                  <br />
                  <Typography
                    variant="body2"
                    sx={{ fontSize: { xs: "12px", md: "16px" } }}
                  >
                    <img src={C} alt="Location Icon" style={styles.iconImage} />{" "}
                    1777 Address
                  </Typography>
                  <br />
                </Box>
              </Box>

              {/* Social Media Section */}
              <Box display={"flex"} sx={{}}>
                <Box>
                  <img src={FText} style={{ height: "5vh", width: "5vh" }} />
                </Box>
                <Box>
                  <img src={InText} style={{ height: "5vh", width: "5vh" }} />
                </Box>
              </Box>
            </Box>

            {/* </Box> */}
            {/* <Box display={"flex"} sx={{ backgroundColor: '#f9f9f9', p: "50px", borderRadius: "15px" }}> */}
            <Box
              display={"flex"}
              sx={{
                alignItems: "center",
                p: 6,
                borderRadius: "15px",
                backgroundColor: "#f9f9f9",
                "@media(max-width:360px)": { p: 2, border: 1 },
              }}
            >
              <ContactForm />
            </Box>
            {/* </Box> */}
          </Box>
        </Box>
      </Layout>
      <Footer />
    </Box>
  );
};

export default Contact;
