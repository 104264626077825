import { Typography, Box } from "@mui/material";
import React from "react";

const AlertChip = ({ imageSrc, label }) => {
  const containerStyles = {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem 4rem",
    gap: "10px",
    width: "423px",
    height: "72px",
    minWidth: "200px",
    minHeight: "40px",
    background: "#DEF1FF",
    border: "1px solid #A4D9FF",
    borderRadius: 50,
    color: "#1A202C",

    "@media (max-width: 600px)": {
      width: "123px",
      padding: "0.5rem 2rem",
      height: "31px",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  const imageStyles = {
    position: "absolute",
    left: "80.19%",
    top: "20%",
    width: "auto",
    height: "60%",
    objectFit: "cover",

    // Mobile responsiveness for screens 360px or smaller
    "@media (max-width: 600px)": {
      left: "85%",
      height: "15%",
    },
  };

  return (
    <Box sx={containerStyles}>
      <Box>
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "600",
            "@media (max-width: 600px)": {
              fontSize: "12px",
              fontWeight: "600",
            },
          }}
        >
          {label}
        </Typography>
      </Box>
      {imageSrc && <img src={imageSrc} alt="Styled" style={imageStyles} />}
    </Box>
  );
};

export default AlertChip;

// import { Typography, Box } from "@mui/material";
// import React from "react";
// import { FontStyle } from '../assets/styles';

// const AlertChip = ({ imageSrc, label, textStyle }) => {
//   const containerStyles = {
//     position: 'relative',
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'center',
//     padding: '1rem 4rem',
//     gap: '10px',
//     width: '423px',
//     height: '72px',
//     minWidth:'200px',
//     minHeight:'40px',
//     background: '#DEF1FF',
//     border: '1px solid #A4D9FF',
//     borderRadius: 50,
//     color: '#1A202C',
//   };

//   const imageStyles = {
//     position: 'absolute',
//     left: '80.19%',
//     top: '20%',
//     width: 'auto',
//     height: '60%',
//      objectFit: 'cover',
//   };

//   return (
//     <Box sx={containerStyles}>
//       <Box >
//       <Typography sx={{fontSize:"30px",fontWeight:"600"}}>{label}</Typography>
//       </Box>
//       {imageSrc && <img src={imageSrc} alt="Styled" style={imageStyles} />}
//     </Box>
//   );
// };

// export default AlertChip;
