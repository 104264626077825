import { Emergency } from "@mui/icons-material";
import React from "react";
import Emergencycall from "../pages/emergencycall";

// export const BASEURL = 'http://192.168.0.68:8000/api/v1'
export const BASEURL = "https://svasth.ai/api/v1";
export const ENDPOINT = {
  Savr: (pin) => `${BASEURL}/savr?pin=${pin}`,
  Validation: `${BASEURL}/validation`,
  GetDetails: (pin) => `${BASEURL}/getdetails?pin=${pin}`,
  Emergency_Call: (id, name, txnId) =>
    `${BASEURL}/emergency_call?id=${id}&name=${name}&txnId=${txnId}`,
};

export default BASEURL;
