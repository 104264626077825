import React from 'react';
import { Box, TextField, Grid, Typography } from '@mui/material';
import TextComponent from "../components/Text";
import Button from "../components/Button";
import TextColor from './TextColor';

const ContactForm = () => {
    return (
        // <Box
        //     display="flex"
        //     alignItems="center"
        //     justifyContent="center"
        //     sx={{
        //         width: { xs: "100%", md: "38.3vw" },
        //         padding: { xs: "20px", md: "40px" },
        //         backgroundColor: "#f9f9f9",
        //         height: "62vh",
                
        //     }}
        // >
        <Box
        component="form"
        sx={{
            width: { xs: "100%", md: "31.3vw" },
            '@media(max-width:360px)':{
                gap:2,                
                alignItems:"center"
            },
           
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            // border: '1px solid #ddd',
            borderRadius: '8px',
            // borderBottomRightRadius: '8px',
            backgroundColor: '#f9f9f9',
            justifyContent: "center",
        }}
    >

            {/* <Box
                component="form"

                sx={{
                    width: "100%",
                    maxWidth: "500px",
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    borderRadius: '8px',
                    backgroundColor: '#f9f9f9',
                }}
            > */}
             <Box sx={{}}>
                <TextColor
                    text="Get Your Health product today!"
                    highlightWords={['product', 'today', '!']}
                    highlightColor="#3f51b5"
                    defaultColor="#000000"
                    styleType="sub11"
                />
            </Box>
   

                {/* Form Fields */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="First Name"
                            variant="standard"
                            name="name"

                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Last Name"
                            variant="standard"
                            name="lastName"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="E-mail"
                            variant="standard"
                            type="email"
                            name="email"

                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Phone Number"
                            variant="standard"
                            type="tel"
                            name="phone"
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Address"
                            variant="standard"
                            name="address"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Message"
                            variant="standard"
                            multiline
                            rows={3}
                            name="message"

                        />
                    </Grid>
                </Grid>

                {/* Submit Button */}
                <Box textAlign="center">
                    <Button text="Submit" />
                </Box>
            </Box>
        // </Box>
    );
};

export default ContactForm;