// // src/components/Footer.js
// import React from "react";
// import { NavLink } from "react-router-dom";
// import styled from "styled-components";

// const FooterContainer = styled.footer`
//   background-color: #343275;
//   color: white;
//   text-align: center;
//   padding-top: 1rem;
//   padding-bottom: 1rem;
//   position: absolute;
//   bottom: 0;
//   width: 100%;
// `;

// const FooterText = styled.p`
//   margin: 0.5rem 0;
//   font-weight: 400;
// `;
// const StyledNavLink = styled(NavLink)`
//   color: white;
//   text-decoration: none;
//   font-size: 1rem;
//   transition: color 0.3s;

//   &.active {
//     color: #ddd; /* Color when the link is active */
//   }

//   &:hover {
//     color: #ddd; /* Color on hover */
//   }
// `;

// const Footer = () => (
//   <FooterContainer>
//     <FooterText>
//       &copy; 2024 ALEgION Health Tech pvt ltd.{" "}
//       <StyledNavLink to="/policy">Privacy Policy</StyledNavLink>
//     </FooterText>
//     <FooterText>
//       New No. 7C, Perumal Koil Street, Nerkundram, Chennai, Tamil Nadu 600107
//     </FooterText>
//   </FooterContainer>
// );

// export default Footer;
import React from "react";
import { Box, Typography, Link } from "@mui/material";
import logo from "../assets/images/footersvasth.png";
import mail from "../assets/images/mail.png";
import phone1 from "../assets/images/phone1.png";
import location from "../assets/images/location.png";
import linkedin from "../assets/images/linkedin.png";
import facebook from "../assets/images/facebook.png";
const Footer = () => {
  return (
    <Box component="footer" sx={styles.footer}>
      <Box sx={styles.footerContent}>
        <Box sx={{ ...styles.logoSection }}>
          <img src={logo} alt="Svasth Logo" style={styles.footerLogo} />
          <Typography variant="body2">
            Aarogyameva Arthra - Health is the only Wealth! At Svasth, we
            prioritize your well-being with innovative solutions to ensure you
            live life to the fullest.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6">Company</Typography>
          <br />
          <Box component="nav">
            <Link href="#" sx={styles.link}>
              Home
            </Link>
            <br />
            <Link href="#" sx={styles.link}>
              About Us
            </Link>
            <br />
            <Link href="#" sx={styles.link}>
              Products
            </Link>
            <br />
            <Link href="#" sx={styles.link}>
              Contact Us
            </Link>
          </Box>
        </Box>
        <Box sx={styles.footerSection}>
          <Typography variant="h6">Get in Touch</Typography>
          <br />
          <Typography variant="body2">
            <img src={mail} alt="Email Icon" style={styles.iconImage} />{" "}
            info@svasth.ai
          </Typography>
          <br />
          <Typography variant="body2">
            <img src={phone1} alt="Phone Icon" style={styles.iconImage} /> +91
            9600068315
          </Typography>
          <br />
          <Typography variant="body2">
            <img src={location} alt="Location Icon" style={styles.iconImage} />{" "}
            New No. 7C, Perumal Koil Street, Nerkundram, Chennai, Tamil Nadu
            600107
          </Typography>
          <br />
        </Box>
        <Box sx={styles.footerSection}>
          <Typography variant="h6">Social Media</Typography>
          <br />
          <Box sx={styles.socialIcons}>
            <a
              href={"https://www.facebook.com/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" style={styles.media} />
            </a>
            <br />
            <br />
            <a
              href={"https://www.linkedin.com/in/raveendran-iyengar-0a01727/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="Linkedin" style={styles.media} />
            </a>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.footerBottom}>
        <Typography variant="body2">© 2024, All Rights Reserved</Typography>
        <Typography variant="body2">
          <Link href="#" sx={styles.footerLink}>
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link href="#" sx={styles.footerLink}>
            Terms & Conditions
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  footer: {
    // flexWrap: "wrap",
    // display: 'flex',
    width: "calc(100% - 160px)",
    background: "linear-gradient(to right, #1e1e1e, #0a192f)",
    padding: "40px 80px",
    color: "#fff",
  },
  footerContent: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "start",
    marginBottom: "20px",
  },
  media: {
    width: "40px",
    height: "40px",
    marginRight: "8px",
    verticalAlign: "middle",
  },

  logoSection: {
    maxWidth: "300px",
  },
  footerLogo: {
    maxWidth: "200px",
    marginBottom: "10px",
  },
  iconImage: {
    width: "20px",
    height: "20px",
    marginRight: "8px",
    verticalAlign: "middle",
  },
  link: {
    color: "#fff",
    textDecoration: "none",
    marginBottom: "10px",
    display: "block",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  socialIcons: {
    display: "flex",
    gap: "10px",
  },
  icon: {
    fontSize: "24px",
    cursor: "pointer",
  },
  footerBottom: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #ccc",
    paddingTop: "10px",
    fontSize: "14px",
  },
  footerLink: {
    color: "#ccc",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

export default Footer;
