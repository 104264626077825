import React from "react";
import { Box, Stack } from "@mui/material";
import Button from "../components/Button";
import { FontStyle } from "../assets/styles";
import { useNavigate } from "react-router-dom";

const FirstPageCard = ({ title, subtitle, backgroundImage }) => {
  const navigate = useNavigate();
  const contactClick = () => {
    navigate("/contact");
  };
  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        borderRadius: "50px",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "10px",
        width: "100%",
        maxWidth: "1721px",
        height: "538px",
        color: "#000000",
        "@media (max-width: 600px)": {
          alignItems: "center",
          width: "320px",
          borderRadius: "25px",
          justifyContent: "flex-start",
        },
      }}
    >
      <Stack
        display="flex"
        flexDirection="column"
        sx={{
          width: "100%",
          maxWidth: "954px",
          height: "auto",
          padding: "40px",
          gap: 2,
          "@media (max-width: 600px)": {
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            "@media (max-width: 600px)": {
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              gap: 2,
            },
          }}
        >
          <Box
            sx={{
              ...FontStyle.h31,
              "@media (max-width: 600px)": {
                alignItems: "center",
                textAlign: "center",
                ...FontStyle.h31,
              },
            }}
          >
            {title}
          </Box>
          <Box
            sx={{
              ...FontStyle.body22,
              "@media (max-width: 600px)": {
                alignItems: "center",
                textAlign: "center",
                ...FontStyle.body22,
              },
            }}
          >
            {subtitle}
          </Box>
          <Box
            display={"flex"}
            sx={{
              "@media (max-width: 600px)": {
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <Button text="Get in touch" onClick={contactClick} />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default FirstPageCard;
