import React from "react";

const ImageComponent = ({ Image, style }) => {
  return (
    <div>
      <img
        src={Image}
        alt="Image"
        style={{
          ...style,
        }}
      />
   
      {/* <div
        style={{
          position: "absolute",
          width: "250px",
          height: "172.21px",
          left: "954.03px",
          top: "808px",
          backgroundImage: `url(${aiImage})`,
          backgroundSize: "cover",
          transform: "rotate(20deg)",
        }}
      /> */}
    </div>
  );
};

export default ImageComponent;





