import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import { IoMdArrowDropdown } from "react-icons/io";

const Nav = styled.nav`
  display: flex;
  align-items: center;
  color: black;
  padding: 1rem 2rem;
  @media (max-width: 600px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

const LogoImage = styled.img`
  height: ${(props) => props.logoHeight || "80px"};
  width: ${(props) => props.logoWidth || "484px"};
  background-color: white;

  @media (max-width: 600px) {
    display: none;
  }
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
`;

const NavItem = styled.li`
  margin: 0 1.5rem;
  position: relative;
`;

const StyledNavLink = styled(NavLink)`
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s;
  display: flex;
  align-items: center;

  &.active {
    color: blue;
  }

  &:hover {
    color: blue;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.open ? "block" : "none")};
  z-index: 10;
`;

const DropdownItem = styled(NavLink)`
  display: block;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  text-decoration: none;
  color: black;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ContactButton = styled.button`
  background-color: #2c3e50;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1a242f;
  }
`;

const NavBar = ({ logo, logoHeight, logoWidth }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClick = () => {
    navigate("/contact");
  };

  return (
    <Nav>
      <LogoContainer>
        <LogoImage
          src={logo}
          alt="Logo"
          logoHeight={logoHeight}
          logoWidth={logoWidth}
        />
      </LogoContainer>
      <NavList>
        <NavItem>
          <StyledNavLink to="/" end>
            Home
          </StyledNavLink>
        </NavItem>
        <NavItem>
          <StyledNavLink to="/about">About</StyledNavLink>
        </NavItem>
        <NavItem onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
          <StyledNavLink to="/product">
            Products <IoMdArrowDropdown />
          </StyledNavLink>
          <Dropdown open={isDropdownOpen}>
            <DropdownItem to="/">QRify</DropdownItem>
            {/* <DropdownItem to="/product2">Product 2</DropdownItem>
            <DropdownItem to="/product3">Product 3</DropdownItem> */}
          </Dropdown>
        </NavItem>
        <NavItem>
          <Button text="Contact us" onClick={handleClick} />
        </NavItem>
      </NavList>
    </Nav>
  );
};

export default NavBar;
